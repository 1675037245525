@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  @apply focus:outline-none focus-within:outline-none;
}

button:focus {
  @apply focus:outline-none focus-within:outline-none;
}

html {
  height: 100%;
  min-height: 100%;
}

body {
  @apply p-0;
  font-size: 16px;
  height: 100%;
  min-height: 100%;
}

#root {
  height: 100%;
  min-height: 100%;
}

.signika {
  font-family: "Signika", "Istok Web", "NotoSans", sans-serif;
}

@layer base {
  ol {
    list-style: decimal inside;
  }
  ul {
    list-style: disc inside;
  }
  h1 {
    font-size: 72px;
  }
  h2 {
    font-size: 65px;
  }
  h3 {
    font-size: 59px;
  }
  h4 {
    font-size: 51px;
  }
  h5 {
    font-size: 43px;
  }
  h6 {
    font-size: 30px;
  }
  p {
    @apply text-base;
  }
}

/* QUILL */

.ql-clipboard {
  display: none;
}

.caption .ql-editor * {
  @apply text-sm text-white;
}

.dark .ql-editor * {
  @apply text-white;
}

.light .ql-editor * {
  @apply text-primary-main;
}
